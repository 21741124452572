import React, { Component, useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { DynamicForm, CustomTop, PaStepper, ButtonInput, CustomTag, NavigationMenu } from '@emerson/dynamic-ui-dev';
// import { DynamicForm } from '../../components-library';
import { Box, Container, MenuItem, Typography, Modal, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { getEnv, getTranslateCode, lowerToUpper, convertUnit, findAnotherKey, findSameKey } from "../../utils/Helper";
import { technologyComparision, fluidPropertiesCalculation } from "../../business-logics/FlowPA/BusinessFunctions";
import { getProductionListData, resetRightAnchorValues, updateCurrentId, updateSizingInput, updateSizingId, setReportPayload, getLeftAnchorData, clearRightAnchorData, getRightAnchorData, updateRightAnchorKeyData, updateRightAnchorData, updateLeftAnchorkeyData } from '../../store/actions/schemaActions';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import store, { RootState } from "../../store/Store";



const Home = ({ ...props }) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const [result, setResult] = useState<any>({});
    const [show_result, setShow_result] = useState(false);
    const [showClear, setShowClear] = useState(false);
    const [initheight, setHeight] = useState(84 + 40);
    const [formError, setFormError] = useState<any>({});

    const { allOptions } = useSelector((state: RootState) => state.schemaData.preferences);

    const schemaData = useSelector(
        (state: RootState) => state.schemaData
    );
    const bu_code = useSelector(
        (state: RootState) => state.schemaData.bu_code
    );

    const sizingInputs = useSelector(
        (state: RootState) => state.schemaData.sizingInputs
    );

    useEffect(()=>{
        setResult(sizingInputs);
    }, [sizingInputs])



    let ChildRef = useRef<any>();

    // the height of the top component is calculated based on the height of the top component and the height of the stepper component
    useEffect(() => {
        let divHeight: any = document.querySelector('#customTopId');
        setHeight(divHeight?.offsetHeight + 84 + 40);
        // when the badges are empty, the clear button is disabled
        if(schemaData.left_anchor.every((element: any) => element.badges.length == 0)){
            setShowClear(false);
        }
    }, [schemaData.left_anchor])




    let activeIndex = useMemo(() => {
        return schemaData.left_anchor.findIndex((obj: any) => obj.ne_id === schemaData.defaultNeId)
    }, [schemaData.defaultNeId])

    const changeBuCode = (bu_code: any) => {
        dispatch(clearRightAnchorData([]));
        setResult({})
        dispatch(getLeftAnchorData({
            business_unit_code: bu_code,
            p_traffic_source_code: 'NORMAL_ENTRY',
            lang: lowerToUpper(i18n.language)
        }))
    }

    const collectBadges = (step_id: any, formData: any, formObj?: any) => {
        let badges: any = [];
        const index: number = formData.findIndex(
            (x: any) => x.id === step_id
        );
        const hiddenCloseArr = [
            "fpa_rh_FluidType",
            "fpa_rh_FluidSource",
            "fpa_rh_Mounting",
            "fpa_rh_WirelessOptions",
            "fpa_rh_InterfaceOptions",
            "fpa_rh_FlowAccuracy",
            "fpa_rh_DensityAccuracy"
        ]
        const disableValueArr = [
            "Historian"
        ]
        formData[index].fields.forEach((field: any) => {
            if(field.value && !(field.display == 'none') && field.type == "RADIO_INPUT"){
                badges.push({
                    label: field.options ? getLabelFromOptions(field.value, field.options) : field.value,
                    id: field.value,
                    name: field.name,
                    hiddenClose: true
                })
            } else {
                
                if (field.value && !(field.display == 'none') && Object.prototype.toString.call(field.value) == '[object Array]') {
                    field.value.forEach((fiel: any) => {
                        badges.push({
                            label: field.options ? getLabelFromOptions(fiel, field.options) : fiel,
                            id: fiel,
                            name: field.name,
                            hiddenClose: hiddenCloseArr.includes(field.name),
                            disableClose: disableValueArr.includes(fiel)
                        })
                    })
                } else if (field.value && !(field.display == 'none') && (Object.prototype.toString.call(field.value) == '[object String]' || Object.prototype.toString.call(field.value) == '[object Number]')) {
                    badges.push({
                        label: field.options ? getLabelFromOptions(field.value, field.options) : field.value,
                        id: field.value,
                        name: field.name,
                        hiddenClose: hiddenCloseArr.includes(field.name)
                    })
                } else if (bu_code == "SPENCE" && field.type == "TABLE_INPUT" && field.value && !(field.display == 'none') && (Object.prototype.toString.call(field.value) == '[object Object]')) {
                   //collect the value from the object
                   let unitKey = findSameKey(field.value, allOptions);
                   let valueKey = findAnotherKey(field.value, unitKey);
                   if(field.value[unitKey] && field.value[valueKey]){
                    badges.push({
                        label: field.value[valueKey] + " " + (allOptions[unitKey] ? getLabelFromOptions(field.value[unitKey], allOptions[unitKey]) : field.value[unitKey]),
                        id: field.value[valueKey],
                        name: field.name,
                        innerName: valueKey,
                        hiddenClose: formObj["hSpence_R3_41_CalculateOutletPressure"] &&  formObj["hSpence_R3_41_CalculateOutletPressure"].toUpperCase() =="YES" && field.name == "hSpence_R3_17_OutletPressure"
                    })
                   }
                }
            }
            
        })
        dispatch(updateLeftAnchorkeyData({
            ne_id: step_id,
            key: "badges",
            value: badges
        }))
    }


    const updateData = (formObj: any, formData: any) => {
        setResult({ ...result, ...formObj })
        dispatch(updateSizingInput({ ...result, ...formObj }));
        collectBadges(schemaData.defaultNeId, formData, { ...result, ...formObj })
    }

    const onNavigationSelect = (sn_id: number, ne_id: number, name: string, bu_code: string) => {
        dispatch(updateCurrentId(ne_id));
    }

    const onRAhandleChange = (e: any, formObj: any, formData: any, name: any, error: object) => {
        //  LTA common start
        const fields = formData.find((res: any) => res.id === schemaData.defaultNeId);
        const field = fields.fields.find((res: any) => res.name === name);
        if (fields && field && field.updateFlag && field.targetField && field.updateKey && field.updateValue) {
            if (field.updateFlag == formObj[name]) {
                dispatch(updateRightAnchorKeyData({
                    ne_id: schemaData.defaultNeId,
                    name: field.targetField,
                    [field.updateKey]: field.updateValue
                }))
            } else {
                const targetObject = fields.fields.find((res: any) => res.name === field.targetField);
                const targetValueInInitOptions = targetObject.initOptions.find((option: any) => option.value === formObj[field.targetField])
                if (!targetValueInInitOptions) {
                    dispatch(updateRightAnchorKeyData({
                        ne_id: schemaData.defaultNeId,
                        name: field.targetField,
                        value: ""
                    }))
                }
                dispatch(updateRightAnchorKeyData({
                    ne_id: schemaData.defaultNeId,
                    name: field.targetField,
                    [field.updateKey]: targetObject.initOptions
                }))
            }
        }
        //  LTA common end
        // fpa start
        if (name == "fpa_rh_TechnologyType") {
            let func = technologyComparision(formObj[name]);
            func.then((data) => {
                dispatch(updateRightAnchorKeyData({
                    ne_id: schemaData.defaultNeId,
                    name: 'fpa_rh_TechnologyComparison',
                    options: data
                }))
            })

        }
        if (name == "fpa_rh_IamnotSure") {
            dispatch(updateRightAnchorKeyData({
                ne_id: schemaData.defaultNeId,
                name: 'fpa_rh_LineSize',
                disabled: formObj['fpa_rh_IamnotSure'].length === 0 ? false : true
            }))
        }
        if (name == "fpa_rh_ProcessVariables") {
            let func = fluidPropertiesCalculation(formObj[name]);

            const index: number = schemaData.right_anchor.findIndex(
                (x: any) => x.id === schemaData.defaultNeId
            );
            let element_index = schemaData.right_anchor[index].fields.findIndex(
                (x: any) => x.name === "fpa_rh_FluidProperties"
            );
            let options = schemaData.right_anchor[index].fields[element_index].options;

            func.then((data) => {
                data = data.data;
                for (const key in data) {
                    options.map((obj: any, index: number) => {
                        obj = obj.map((res: any, idx: number) => {
                            if (res.name === key && data[key]) {
                                options[index][idx].value = Number(data[key]).toFixed(4)
                            }
                        });
                    });
                }
                dispatch(updateRightAnchorKeyData({
                    ne_id: schemaData.defaultNeId,
                    name: 'fpa_rh_FluidProperties',
                    options: options
                }))
            })
        }
        if (name == "fpa_lh_CONFIGURE_Submit" || name == "fpa_lh_WORKSPACE_Submit") {
            setShow_result(true);
        }
        // fpa end
        // espically for spence when click on the valve category, it will go to the next page
         if(name == "hSpence_r1_ValveCategory"){
            let myDiv: any = document.getElementById('standPaStepperId');
            if(myDiv){
                let button = myDiv.querySelectorAll('button')[1];
                button && button.click();
            }
        }
        //unit conversion start
        if(bu_code == "SPENCE" && result[name] && formObj[name] && e.name && !(e.dynamicVariable == false) && e.type === "SINGLE_SELECT" && allOptions[e.name]){

            let key:any = Object.keys(formObj[name]).find((ite:any)=>ite != e.name);

            if(formObj[name][key]){
                let targetValue = convertUnit(formObj[name][key],  result[name][e.name], formObj[name][e.name], allOptions[e.name]);
                if(targetValue){
                    formObj[name][key] = targetValue;
                }
            }
        }
        //unit conversion end
        // caculate the outletPressure for spence
        let changeArr = ["hSpence_R3_45_IsCondensateRecovered","hSpence_R3_53_HeightOfTheTrapOutletRise", "hSpence_R3_70_TotalPipeLengthFromTheTrapToTheRecoveryTank", "hSpence_R3_74_PressureOfTheCondensateRecoveryTank"];
        if (changeArr.includes(name)) {
            if(formObj["hSpence_R3_45_IsCondensateRecovered"] == "Yes" &&
                formObj["hSpence_R3_53_HeightOfTheTrapOutletRise"]["heightoftheTrapOutletRise"] &&
                formObj["hSpence_R3_70_TotalPipeLengthFromTheTrapToTheRecoveryTank"]["totalPipeLengthfromtheTraptotheRecoveryTank"] &&
                formObj["hSpence_R3_74_PressureOfTheCondensateRecoveryTank"]["pressureoftheCondensateRecoverytank"]){    
                // step1: convertUnit  heightoftheTrapOutletRise
                let heightoftheTrapOutletRise = formObj["hSpence_R3_53_HeightOfTheTrapOutletRise"]["heightoftheTrapOutletRise"];
                let heightoftheTrapOutletRiseUnit = formObj["hSpence_R3_53_HeightOfTheTrapOutletRise"]["length"];
                let targetHeightoftheTrapOutletRiseUnit = "length.ft";

                let heightoftheTrapOutletRiseValue = convertUnit(heightoftheTrapOutletRise, heightoftheTrapOutletRiseUnit, targetHeightoftheTrapOutletRiseUnit, allOptions['length']);
                
                // step2: convertUnit  totalPipeLengthfromtheTraptotheRecoveryTank
                let totalPipeLengthfromtheTraptotheRecoveryTank = formObj["hSpence_R3_70_TotalPipeLengthFromTheTrapToTheRecoveryTank"]["totalPipeLengthfromtheTraptotheRecoveryTank"];
                let totalPipeLengthfromtheTraptotheRecoveryTankUnit = formObj["hSpence_R3_70_TotalPipeLengthFromTheTrapToTheRecoveryTank"]["length"];
                let targetTotalPipeLengthfromtheTraptotheRecoveryTankUnit = "length.ft";
                let totalPipeLengthfromtheTraptotheRecoveryTankValue = convertUnit(totalPipeLengthfromtheTraptotheRecoveryTank, totalPipeLengthfromtheTraptotheRecoveryTankUnit, targetTotalPipeLengthfromtheTraptotheRecoveryTankUnit, allOptions['length']);

                // step3: convertUnit  pressureoftheCondensateRecoveryTank
                let pressureoftheCondensateRecoveryTank = formObj["hSpence_R3_74_PressureOfTheCondensateRecoveryTank"]["pressureoftheCondensateRecoverytank"];
                let pressureoftheCondensateRecoveryTankUnit = formObj["hSpence_R3_74_PressureOfTheCondensateRecoveryTank"]["pressure"];
                let targetPressureoftheCondensateRecoveryTankUnit = "pressure.psig";
                let pressureoftheCondensateRecoveryTankValue = convertUnit(pressureoftheCondensateRecoveryTank, pressureoftheCondensateRecoveryTankUnit, targetPressureoftheCondensateRecoveryTankUnit, allOptions['pressure']);

                // step4: calulate the outletPressure use the stand unit,and then convert to the target unit
                let outletPressure = (heightoftheTrapOutletRiseValue * 0.5) + (totalPipeLengthfromtheTraptotheRecoveryTankValue * 0.01) + pressureoftheCondensateRecoveryTankValue*1;
                let outletPressureUnit = "pressure.psig";
                let targetOutletPressureUnit = formObj["hSpence_R3_17_OutletPressure"]["pressure"];
                let outletPressureValue = convertUnit(outletPressure, outletPressureUnit, targetOutletPressureUnit, allOptions['pressure']);
                formObj["hSpence_R3_17_OutletPressure"]["outletPressure"] = outletPressureValue;

                // hSpence_R3_1_InletPressure should be less than hSpence_R3_17_OutletPressure
                if(formObj["hSpence_R3_1_InletPressure"]["inletPressure"] && formObj["hSpence_R3_1_InletPressure"]["pressure"]){
                    let inletPressure = formObj["hSpence_R3_1_InletPressure"]["inletPressure"];
                    let inletUnit = formObj["hSpence_R3_1_InletPressure"]["pressure"];
                    let outUnit = formObj["hSpence_R3_17_OutletPressure"]["pressure"];
                    let outletPressure = formObj["hSpence_R3_17_OutletPressure"]["outletPressure"];
                    let sourceValue = convertUnit(inletPressure, inletUnit, outUnit, allOptions['pressure']);
                    if(Number(sourceValue) < Number(outletPressure)){
                        setFormError({"hSpence_R3_17_OutletPressure": {'outletPressure': "Outlet Pressure value should be less than Inlet Pressure"}});
                    } else {
                        setFormError({"hSpence_R3_17_OutletPressure": {'outletPressure': ""}});
                    }
                }
            }
        }
        // caculate the outletPressure for spence

        // inlet pressure should be greater than the  outletPressure
        if (bu_code == "SPENCE" && name.includes('hSpence_R3_1_InletPressure') && formObj[name] && formObj[name]['inletPressure']) {
            let outletFiledPressureName = Object.keys(formObj).find(item => item.includes('OutletPressure'));
            if(outletFiledPressureName && formObj[outletFiledPressureName] && formObj[outletFiledPressureName]['outletPressure'] && formObj[outletFiledPressureName]['pressure']){
                let outletPressure = formObj[outletFiledPressureName]['outletPressure'];
                let outUnit = formObj[outletFiledPressureName]['pressure'];
                let inletUnit = formObj[name]['pressure'];
                let inletPressure = formObj[name]['inletPressure'];
                let sourceValue = convertUnit(outletPressure, outUnit, inletUnit, allOptions['pressure']);
                if(Number(inletPressure) < Number(sourceValue)){
                    setFormError({[outletFiledPressureName]: {'outletPressure': "Outlet Pressure value should be less than Inlet Pressure"}});
                } else {
                    setFormError({[outletFiledPressureName]: {'outletPressure': ""}});
                }
            }
        }
        // espically for spence end
        dispatch(updateRightAnchorData({
            ne_id: schemaData.defaultNeId,
            value: formData
        }))
        updateData(formObj, formData)
    }


    const getLabelFromOptions = (value: any, options: any[]) => {
        const object = options.find((option) => option.value ? option.value == value : option.id == value);
        return object && object.label ? object.label : value
    }



    const onRAhandleKeyPress = (e: any) => {

    }

    const onStepchange = async (item: any, index: any) => {
        if (index > activeIndex && ChildRef && ChildRef.current && ChildRef.current.validationFormAllLogic) {
            let forward = await ChildRef.current.validationFormAllLogic();
            if (!forward) {
                return ""
            }
        }
        const { sn_id, ne_id, name, bu_code, conditions } = item;
        if (bu_code == 'LTA' && index == schemaData.left_anchor.length - 1) {
            dispatch(getProductionListData({ ...result, sn_id, ne_id, name, bu_code, lang: lowerToUpper(i18n.language), notrenderd: schemaData.right_anchor.filter((obj: any) => obj.id === ne_id).length == 0 }))
        } else {
            if (schemaData.right_anchor.filter((obj: any) => obj.id === ne_id).length === 0) {
                
                let p_request_json:any = {};
                for (let i = 0; i < schemaData.right_anchor.length; i++) {
                    if (schemaData.right_anchor[i].id === ne_id) {
                        break;
                    }
                    schemaData.right_anchor[i].fields.forEach((field: any) => {
                        if(field.display == "block"){
                            p_request_json[field.name] = field.value;
                        }
                    });
                }
                dispatch(updateSizingInput(p_request_json));
                dispatch(getRightAnchorData({
                    business_unit_code: bu_code,
                    p_session_navigation_id: sn_id,
                    p_ne_id: ne_id,
                    p_request_json: p_request_json,
                    lang: lowerToUpper(i18n.language),
                    conditions: conditions,
                    notrenderd: schemaData.right_anchor.filter((obj: any) => obj.id === ne_id).length == 0,
                    lastpage: index == schemaData.left_anchor.length - 1
                }));
            }
        }
        onNavigationSelect(sn_id, ne_id, name, bu_code);
    }

    const deleteHander = (tagObject: any, item: any) => {
        const { label, id, name, innerName } = tagObject;
        if (result[name]) {
            let leftValue = '';
            if (Object.prototype.toString.call(result[name]) == '[object Array]') {
                leftValue = result[name].filter((fie: any) => fie !== id);
            }

            if (Object.prototype.toString.call(result[name]) == '[object Object]' && innerName) {
                let leftvalue = JSON.parse(JSON.stringify(result[name]));
                leftvalue[innerName] = '';
                leftValue = leftvalue
            }
            setResult({ ...result, [name]: leftValue })
            dispatch(updateSizingInput({ ...result, [name]: leftValue }));
            dispatch(updateRightAnchorKeyData(
                {
                    ne_id: item.ne_id,
                    name: name,
                    value: leftValue
                }
            ))
        }
        const updateItem = schemaData.left_anchor.find((ite: any) => ite.ne_id == item.ne_id)
        const filterIndex = updateItem.badges.findIndex((ite: any) => (ite.id === tagObject.id && ite.name === tagObject.name));
        const badges = updateItem.badges.filter((item: any, index: number) => index !== filterIndex)
        dispatch(updateLeftAnchorkeyData(
            {
                ne_id: item.ne_id,
                key: "badges",
                value: badges
            }
        ))
        if (name == "fpa_rh_TechnologyType") {
            let func = technologyComparision(result[name]);
            func.then((data) => {
                dispatch(updateRightAnchorKeyData({
                    ne_id: item.ne_id,
                    name: 'fpa_rh_TechnologyComparison',
                    options: data
                }))
            })
        }
        
        if (name === 'fpa_rh_IamnotSure') {
            if (result["fpa_rh_LineSize"]) {
                dispatch(updateLeftAnchorkeyData(
                    {
                        ne_id: item.ne_id,
                        key: "badges",
                        value: [{
                            label: result["fpa_rh_LineSize"],
                            id: result["fpa_rh_LineSize"],
                            name: "fpa_rh_LineSize",
                        }]
                    }
                ))
            }
            dispatch(updateRightAnchorKeyData({
                ne_id: item.ne_id,
                name: 'fpa_rh_LineSize',
                disabled: false
            }))
        }
        // especially For Spence start
        if(name == "hSpence_r2_8_Technology"){
            dispatch(updateRightAnchorKeyData({
                ne_id: item.ne_id,
                name: 'hSpence_r2_9_Application',
                validations: [{
                    validationType: "REQUIRED",
                    validationMessage: "Either techonology or application or both can be filled out"
                }]
            }))
        }
        if(name == "hSpence_r2_9_Application"){
            dispatch(updateRightAnchorKeyData({
                ne_id: item.ne_id,
                name: 'hSpence_r2_8_Technology',
                validations: [{
                    validationType: "REQUIRED",
                    validationMessage: "Either techonology or application or both can be filled out"
                }]
            }))
        }

        if(name =="hSpence_R3_57_RequiredCv"){
            if(result.hasOwnProperty("hSpence_R3_43_RequiredSteamFlow")){
                dispatch(updateRightAnchorKeyData({
                    ne_id: item.ne_id,
                    name: 'hSpence_R3_43_RequiredSteamFlow',
                    validations: {
                        "requiredSteamFlow":[
                            {
                                validationMessage: "This is required",
                                validationType: "REQUIRED"
                            },
                            {
                                pattern: "^(^\\d*(\\.[0-9]{1,3}$)$)|(^\\d*$)|(^0$)$",
                                validationMessage: "Please enter a number greater than or equal to 0 and up to 3 decimal places",
                                validationType: "PATTERN"
                            }
                        ]
                    }
                }))
            }
        }

        if(name =="hSpence_R3_58_RequiredCv"){
            if(result.hasOwnProperty("hSpence_R3_33_RequiredGasFlow") && result['hSpence_r2_1_FluidType']=="Gas/Vapor"){
                dispatch(updateRightAnchorKeyData({
                    ne_id: item.ne_id,
                    name: 'hSpence_R3_33_RequiredGasFlow',
                    validations: {
                        "requiredGasFlow":[
                            {
                                validationMessage: "This is required",
                                validationType: "REQUIRED"
                            },
                            {
                                pattern: "^(^\\d*(\\.[0-9]{1,3}$)$)|(^\\d*$)|(^0$)$",
                                validationMessage: "Please enter a number greater than or equal to 0 and up to 3 decimal places",
                                validationType: "PATTERN"
                            }
                        ]
                    }
                }))
            } else if(result.hasOwnProperty("hSpence_R3_34_RequiredLiquidFlow") && result['hSpence_r2_1_FluidType']=="Liquid"){
                dispatch(updateRightAnchorKeyData({
                    ne_id: item.ne_id,
                    name: 'hSpence_R3_34_RequiredLiquidFlow',
                    validations: {
                        "requiredLiquidFlow":[
                            {
                                validationMessage: "This is required",
                                validationType: "REQUIRED"
                            },
                            {
                                pattern: "^(^\\d*(\\.[0-9]{1,3}$)$)|(^\\d*$)|(^0$)$",
                                validationMessage: "Please enter a number greater than or equal to 0 and up to 3 decimal places",
                                validationType: "PATTERN"
                            }
                        ]
                    }
                }))

            } else if(result.hasOwnProperty("hSpence_R3_43_RequiredSteamFlow") && result['hSpence_r2_1_FluidType']=="Steam"){
                dispatch(updateRightAnchorKeyData({
                    ne_id: item.ne_id,
                    name: 'hSpence_R3_43_RequiredSteamFlow',
                    validations: {
                        "requiredSteamFlow":[
                            {
                                validationMessage: "This is required",
                                validationType: "REQUIRED"
                            },
                            {
                                pattern: "^(^\\d*(\\.[0-9]{1,3}$)$)|(^\\d*$)|(^0$)$",
                                validationMessage: "Please enter a number greater than or equal to 0 and up to 3 decimal places",
                                validationType: "PATTERN"
                            }
                        ]
                    }
                }))
            }
            
        }
        

        if(name =="hSpence_R3_59_RequiredCv"){
            if(result.hasOwnProperty("hSpence_R3_43_RequiredSteamFlow")){
                dispatch(updateRightAnchorKeyData({
                    ne_id: item.ne_id,
                    name: 'hSpence_R3_43_RequiredSteamFlow',
                    validations: {
                        "requiredSteamFlow":[
                            {
                                validationMessage: "This is required",
                                validationType: "REQUIRED"
                            },
                            {
                                pattern: "^(^\\d*(\\.[0-9]{1,3}$)$)|(^\\d*$)|(^0$)$",
                                validationMessage: "Please enter a number greater than or equal to 0 and up to 3 decimal places",
                                validationType: "PATTERN"
                            }
                        ]
                    }
                }))
            }
        }

        
        // especially For Spence end
        //LTA common start
        const fields = schemaData.right_anchor.find((res: any) => res.id === item.ne_id);
        const field = fields.fields.find((res: any) => res.name === name);
        if (fields && field && field.updateFlag && field.targetField && field.updateKey && field.updateValue) {
            if (field.updateFlag == id) {
                const targetObject = fields.fields.find((res: any) => res.name === field.targetField);
                const targetValueInInitOptions = targetObject.initOptions.find((option: any) => option.value === targetObject.value)
                if (!targetValueInInitOptions) {
                    dispatch(updateRightAnchorKeyData({
                        ne_id: item.ne_id,
                        name: field.targetField,
                        value: ""
                    }))
                    dispatch(updateLeftAnchorkeyData({
                        ne_id: item.ne_id,
                        key: "badges",
                        value: badges.filter((ite: any) => ite.id !== targetObject.value)
                    }))
                }
                dispatch(updateRightAnchorKeyData({
                    ne_id: item.ne_id,
                    name: field.targetField,
                    [field.updateKey]: targetObject.initOptions
                }))
            }
        }
        //LTA common end
        onNavigationSelect(item.sn_id, item.ne_id, item.name, item.bu_code);
    }

    const handleClose = () => { setShow_result(false); };

    const handleClear = () => {
        setShowClear(true);
    }

    const handleCheckbox = (e:any) => {
        if(e.target.value == 'YES'){
            dispatch(resetRightAnchorValues());
            dispatch(updateSizingId(""));
            dispatch(updateCurrentId(schemaData.left_anchor[0].ne_id));
            dispatch(updateSizingInput({}));
        }
        setShowClear(false);
    };

    const disableClear = () => {
        return schemaData.left_anchor.every((element: any) => element.badges.length == 0)
    }


    const handleSubmit = (event: any, type: any, name: any, value: any) => {
        dispatch(setReportPayload(value));
        props.setCurrentPage('report')
    }


    return (
        <Container maxWidth="xl" sx={{
            '&.MuiContainer-root': {
                paddingTop: '0'
            }
        }}>
             <div className="ddl-grid ddl-grid--cols-12" style={{height:"40px"}}>
                <div className='ddl-grid--col-span-3'>
                </div>
                <div className='ddl-grid--col-span-9' >
                    {schemaData.left_anchor.length !== 0 &&
                        <PaStepper id="standPaStepperId" activeIndex={activeIndex} onChange={onStepchange} data={schemaData.left_anchor} />
                    }
                </div>
            </div>
            <div className="ddl-grid ddl-grid--cols-12">
                <div className='ddl-grid--col-span-3 custom-scrollbar' style={{ height: `calc(100vh - ${initheight}px)`, overflow: 'auto' }}>
                    <div> {
                        schemaData.left_anchor.length !== 0 &&
                        <NavigationMenu
                            data={schemaData.left_anchor}
                            onDelete={deleteHander}
                            onSelect={onNavigationSelect}
                        />
                    }
                    </div>
                    {/* Start Clear Button func */}
                    {/* {<div style={{ marginTop: 10 }}>
                        <CustomTag showWarnMsg={showClear} sendMsgVal={(e: any) => getValueShow(e)} onChange={handleCheckbox} />
                    </div>} */}
                    {showClear&&<Box sx={{ width: '100%', maxWidth: 500 }}>

                        <Typography sx={{ margin:'8px',color:'#e81123', cursor:'default'}} variant="subtitle1" gutterBottom>
                            "It will reset the record. Are you sure you want to continute?"
                        </Typography>
                        <FormGroup row>
                            <FormControlLabel
                                sx={{
                                    '&.MuiFormControlLabel-root': {
                                        marginLeft: '8px',
                                        '& .MuiFormControlLabel-label': {
                                            fontSize: '14px',
                                            color: '#00000066'
                                        }
                                    }
                                }}
                                labelPlacement="start"
                                control={<Checkbox size={'small'} value="YES"
                                    onChange={(e) => {
                                        e.stopPropagation()
                                        handleCheckbox(e)
                                    }} />}
                                label="YES"
                            />
                            <FormControlLabel
                                sx={{
                                    '&.MuiFormControlLabel-root': {
                                        marginLeft: '8px',
                                        '& .MuiFormControlLabel-label': {
                                            fontSize: '14px',
                                            color: '#00000066'
                                        }
                                    }
                                }}

                                labelPlacement="start"
                                control={<Checkbox size={'small'} value="NO"
                                    onChange={(e) => {
                                        e.stopPropagation()
                                        handleCheckbox(e)
                                    }} />}
                                label="NO"
                            />
                        </FormGroup>
                    </Box>}
                    {schemaData.left_anchor.length > 0 &&
                        <div className={"clear_btn"}>
                        <a className={ disableClear() ? 'disable-btn': 'clear-all'} onClick={handleClear}>{t("CLEAR_ALL")} </a>
                        </div>}
                    {/* End Clear Button func */}
                </div>
                <div className='ddl-grid--col-span-9' style={{ height: `calc(100vh - ${initheight}px)`, overflow: 'auto'}}>
                    <div>
                        {schemaData.right_anchor.filter((obj: any) => obj.id === schemaData.defaultNeId).length !== 0 &&
                            <DynamicForm  ref={ChildRef} formError={formError} values= {result} schema={schemaData.right_anchor.filter((obj: any) => obj.id === schemaData.defaultNeId) as any} updateData={updateData} handleChange={onRAhandleChange} handleKeyPress={onRAhandleKeyPress}  onSubmit={handleSubmit} formKey={schemaData.defaultNeId}/>
                        }
                    </div>
                </div>
            </div>

            <Modal
                open={show_result}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "50%",
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <span>Selections</span> <span onClick={handleClose} style={{ float: "right", color: "gray", cursor: "pointer" }}>X</span>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <div style={{ height: "400px", overflowY: "auto", width: "100%" }}><table className="table table-hover">
                            <tbody>
                                <tr>
                                    <th>Key</th>
                                    <th>Value</th>
                                </tr>
                                {result && Object.keys(result).map((key: any, value: any) => (
                                    <tr className="table-row" key={key}>
                                        <td key={`tablekey-${key}`}>{key}</td>
                                        <td key={`tablevalue-${key}`}>{result[key] ? result[key].toString() : ''}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table></div>
                    </Typography>
                </Box>
            </Modal>
        </Container>
    );
}


export default React.memo(Home);
