export const isElementsExists = (arr: any, name: any) => {
  return arr.some(function (el: any) {
    return el.label === name;
  });
};

export const upsert = (array: any, item: any, key: string) => {
  const i = array.findIndex((_item: any) => _item[key] === item[key]);
  if (i > -1) array[i] = item;
  else array.push(item);
};

export const getEnv = (name: string) => {
  // @ts-ignore
  const RUNTIME_ENV = window?._env_?.[name]
    ? // @ts-ignore
      window._env_[name]
    : // @ts-ignore
      process.env[name];

  return RUNTIME_ENV;
};

export const isJson = (input: string) => {
  try {
    JSON.parse(input);
  } catch (e) {
    return false;
  }
  return true;
};

export const getTranslateCode = (std_code: string, arr: any[] ) => {
  try {
    const transObject = arr.find((item)=> item.std_code==std_code);
    return transObject.trans_code ? transObject.trans_code : "en_US"
  } catch (e) {
    return "en_US"
  }
};

export const lowerToUpper = (language: string) => {
  try {
    return language.replace(`_${language.split('_')[1]}`, `_${language.split('_')[1].toUpperCase()}`)
  } catch (e) {
    return "en_US"
  }
};

export const getValueFromJson = (obj: any, propString: string) => {

  if (!propString)
    return obj;

  if (!propString.includes('.'))
    return obj[propString]; 

  return propString.split('.').reduce((o, k) => o?.[k], obj);

}



export const setValueByPath = (json: any, path: string, value: any) => {
  if(!path){
    return json;
  }
  if (!path.includes('.')){
    json[path] = value;
    return json;
  }

  const pathArray = path.split('.');
  const lastKey: any= pathArray.pop();
  const lastObject = pathArray.reduce((obj, key) =>
    obj[key] || (obj[key] = {}), json);
  lastObject[lastKey] = value;
  return json;
}


export const convertUnit = (value:any, orginUnit:any, targetUnit:any, units: any[]) => {
  if (orginUnit === targetUnit) {
      return parseFloat(value).toFixed(3).toString();
  }
  if (units && value !== "") {
    const fromUnit = units.find((ite:any)=> ite.value == orginUnit);
    const toUnit = units.find((ite:any)=> ite.value == targetUnit);
    if (!fromUnit || !toUnit) {
      console.error(`Unit conversion error from PA: ${fromUnit} or ${toUnit} not found.`);
      return value;
    }
    const inputValue = parseFloat(value);
    const fromUnitFactor = parseFloat(fromUnit["unitFactor"]);
    const toUnitFactor = parseFloat(toUnit["unitFactor"]);
    const fromUnitOffset = parseFloat(fromUnit["unitOffset"]);
    const toUnitOffset = parseFloat(toUnit["unitOffset"]);
    const toValue = ((inputValue + fromUnitOffset) / fromUnitFactor * toUnitFactor) - (toUnitOffset);
    return toValue.toFixed(3).toString();
  }
  return value;
}


export const isFraction = (value: any) =>{
  if(!value){
    return false;
  }
  if(Object.prototype.toString.call(value) !== '[object String]'){
    return false;
  }
  const fractionRegex = /^(\d+)\/(\d+)$/;
  const match = value.match(fractionRegex);
  return match !== null && match[1] !== '0' && match[2] !== '0';
}

export const findAnotherKey = (obj: any, key: string) => {
  for (const k in obj) {
    if (k !== key) {
      return k;
    }
  }
  return '';
}

export const findSameKey = (obj: any, obj1: any) => {
  for (const k in obj) {
    if (obj1[k]) {
      return k;
    }
  }
  return '';
}



