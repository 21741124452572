import { compose } from "@reduxjs/toolkit";
import { takeLatest, put, call, select, takeEvery } from "redux-saga/effects";
import { getAllSizing, updateAlertMessage, getRightAnchorData } from "../../store/actions/schemaActions";
import {
  getSchemaAPI,
  getCalculationAPI,
  getSizingResultsAPI,
  getLeftAnchorDataAPI,
  getRightAnchorDataAPI,
  getProductionListAPI,
  getLanguageListAPI,
  getExtOktaSession,
  getIntOktaSession,
  deleteUserOktaSession,
  deleteIntUserOktaSession,
  fetchUOMData,
  customAPI,
  updateUserPreferenceAPI,
  getAllSizingAPI,
  saveSizingAPI,
  onFetchSizingAPI,
  getUserPreferenceAPI
} from "../../api/home";
import { getPreferenceSchemaAPI } from "../../api/preference";
import { getSizingInputSchemaAPI } from "../../api/sizinginput";
import Constant from "../constants/schemaConstants";
import i18n from "i18next";
import { isJson, getValueFromJson, setValueByPath} from "../../utils/Helper";

export interface ResponseGenerator {
  data?: any;
}

export interface SelectGenerator {
  displayUnit?: any;
}

export const getErrorMessage = (error: any) => {
  if (error.response) {
    if(error.response.status == 500 || error.response.status == 405 || error.response.status == 401){
      return  error.response.data.detail
    } else if(error.response.status == 400){
      return  error.response.data
    } else {
      return error.message
    }
  }
}


export function* storeSchema() {
  yield takeLatest(Constant.STORE_SCHEMA, storeSchemaSaga);
}
export function* storeSchemaSaga(action: any) {
  try {
    const { data } = yield call(getSchemaAPI, {
      sn_id: action.sn_id,
      sne_id: action.sne_id,
      value: action.value,
    });
    yield put({ type: Constant.STORE_SCHEMA_SUCCESS, data });
  } catch (error) {
    yield put({ type: Constant.STORE_SCHEMA_ERROR, error });
  }
}

export function* storeCalculation() {
  yield takeLatest(Constant.STORE_CALCULATION, storeCalculationSaga);
}
export function* storeCalculationSaga(action: any) {
  try {
    const { data } = yield call(getCalculationAPI, action.payload);
    yield put({ type: Constant.STORE_CALCULATION_SUCCESS, data });
  } catch (error) {
    yield put({ type: Constant.STORE_CALCULATION_ERROR, error });
  }
}

export function* getLanguageList() {
  yield takeLatest(Constant.STORE_LANGUAGE_LIST, getLanguageListSaga);
}
export function* getLanguageListSaga() {
  try {
    const { data } = yield call(getLanguageListAPI);
    yield put({ type: Constant.STORE_LANGUAGE_LIST_SUCCESS, data });
  } catch (error) {
    yield put({ type: Constant.STORE_LANGUAGE_LIST_ERROR, error });
  }
}

export function* storeSizingResults() {
  yield takeLatest(Constant.STORE_SIZING_RESULTS, storeSizingResultsSaga);
}
export function* storeSizingResultsSaga(action: any) {
  try {
    const { data } = yield call(getSizingResultsAPI, action.payload);
    yield put({ type: Constant.STORE_SIZING_RESULTS_SUCCESS, data });
  } catch (error) {
    yield put({ type: Constant.STORE_SIZING_RESULTS_ERROR, error });
  }
}


export function* storeAllSizing() {
  yield takeLatest(Constant.GET_ALL_SIZING, storeAllSizingSaga);
}
export function* storeAllSizingSaga(action: any) {
  try {
    let dataCopy: any[] = [];
    const { data }:ResponseGenerator = yield call(getAllSizingAPI, action.payload);
    if(data.data){
      dataCopy = data.data
    }
    let result = [];
    result = dataCopy.map((item:any) => {
      let {
        userId,
        sizingId,
        userDetails,
      } = item;
      let inputPayload:any = JSON.parse(item.inputPayload);
      let { 
        hSpence_r1_ValveCategory,
        hSpence_r2_1_FluidType,
        hSpence_r2_6_Technology,
        hSpence_r2_7_Application,
        hSpence_r2_5_placeholder, // not used
        leftAnchor,
        rightAnchor,
        defaultNeId,
         ...restOject} = inputPayload;

      if(restOject.sizingId){
        delete restOject.sizingId;
      }   

      return { 
        userId,
        sizingId,
        userDetails,
        hSpence_r1_ValveCategory,
        hSpence_r2_1_FluidType,
        hSpence_r2_6_Technology,
        hSpence_r2_7_Application,
        sizingInputs:{
          hSpence_r1_ValveCategory,
          hSpence_r2_1_FluidType,
          hSpence_r2_6_Technology,
          hSpence_r2_7_Application,
          ...restOject
        },
        savedParameters: JSON.stringify(restOject),
        leftAnchor,
        rightAnchor,
        defaultNeId
      };
    });
    yield put({ type: Constant.GET_ALL_SIZING_SUCCESS, data: result});
  } catch (error) {
    yield put({ type: Constant.GET_ALL_SIZING_ERROR, error });
  }
}

export function* storeLeftAnchor() {
  yield takeLatest(Constant.STORE_LEFT_ANCHOR, storeLeftAnchorSaga);
}
export function* storeLeftAnchorSaga(action: any) {
  try {
    const { data } = yield call(getLeftAnchorDataAPI, action.payload);
    const fields = data.fields;
    const bu_title = data.bu_title;
    const headerFlag = data.headerflag;
    const left_anchor = fields.filter((item: any) => item.left_right_anchor_flag === 'L');
    const top_anchor = fields.filter((item: any) => item.left_right_anchor_flag === 'T');
    if(left_anchor){
      left_anchor.forEach((item: any) => {
        if(item["attribute_code_json"] && isJson(item["attribute_code_json"])){
          item.attribute_code_value = JSON.parse(item["attribute_code_json"]);
          item.conditions = JSON.parse(item["attribute_code_json"]).conditions;
        }
      });
    }
    yield put({ type: Constant.STORE_TOP_ANCHOR, top_anchor });
    yield put({ type: Constant.STORE_LEFT_ANCHOR_SUCCESS, left_anchor  });
    yield put({ type: Constant.STORE_GET_ELEMENT_DATA, data: {bu_title, headerFlag} });
    if (left_anchor && left_anchor[0]) {
      yield put({ type: Constant.UPDATE_CURRENT_NEID, payload: left_anchor[0].ne_id });
      yield put(getRightAnchorData({
        business_unit_code: left_anchor[0].bu_code,
        p_session_navigation_id: left_anchor[0].sn_id,
        p_ne_id: left_anchor[0].ne_id,
        p_request_json: JSON.stringify({}),
        lang: action.payload.lang,
        conditions: left_anchor[0].conditions
      }))
    }
  } catch (error:any) {
    console.log("error", error)
    yield put({ type: Constant.STORE_LEFT_ANCHOR_ERROR, error });
    yield put(updateAlertMessage(getErrorMessage(error)));
  }
}

export function* storeRightAnchor() {
  yield takeLatest(Constant.STORE_RIGHT_ANCHOR, storeRightAnchorSaga);
}



  // let conditions = [
  // {
  //   "apiURL": "https://d1-productadvisor.emerson.com/assembler/assembler/spence_results",
  //   "method": "post",
  //   "header": {}, 
  //   "map": {
  //     "a1": "a1Change.aa",
  //     "a2": "a2Change"
  //   },
  //   "actions": [
  //     {
  //       "sourcePath": "correctedValues.projectnumber",
  //       "targetPath": "projectnumber.data",
  //     }
  //   ]
  // }]

export function* storeRightAnchorSaga(action: any) {
  try {
    let conditions = action.payload.conditions;
    delete action.payload.conditions
    let lastpage = action.payload.lastpage;
    delete action.payload.lastpage
    const { data } = yield call(getRightAnchorDataAPI, action.payload);
    if(conditions){
      for (let i = 0; i < conditions.length; i++) {
        const condition = conditions[i];
        let request_json:any =  action.payload.p_request_json;
        const response:ResponseGenerator = yield call(customAPI, condition.method, condition.apiURL, condition.header, request_json);
        if(response.data){
          const actions = conditions[i].actions;
          for(let i = 0; i < actions.length; i++){
            let sourcedata = getValueFromJson(response.data, actions[i].sourcePath);
            if(sourcedata != undefined){
              setValueByPath(data, actions[i].targetPath, sourcedata)
            }
          }
        }
      }
    }
    yield put({ type: Constant.STORE_RIGHT_ANCHOR_SUCCESS, data });
    const bu_code = action.payload.business_unit_code;
    if(bu_code == 'SPENCE' && !lastpage){
      yield put({ type: Constant.UPDATE_OPTIONS_BY_PREFERECES });
    }
  } catch (error:any) {
    yield put({ type: Constant.STORE_RIGHT_ANCHOR_ERROR, error });
    yield put(updateAlertMessage(getErrorMessage(error)));
  }
}

export function* getProductionList() {
  yield takeLatest(Constant.STORE_PRODUCTION_LIST, getProductionListSaga);
}

export function* getProductionListSaga(action: any) {
  const notrenderd = action.payload.notrenderd;
  delete action.payload.notrenderd
  const params = {
    "language_code": "en-us",
    "business_unit": "LTA",
    "industry": "",
    "media_state": "",
    "application": "",
    "measured_variable": "",
    "communication_protocol": "",
    "application_requirements": "",
    "fitting_location": "",
    "vessel_type": "",
    "vessel_dimension": "",
    "vessel_dimension_unit": "m",
    "process_pressure_min": "",
    "process_pressure_min_unit": "bar",
    "process_pressure_max": "",
    "process_pressure_max_unit": "bar",
    "process_temperature_min": "",
    "process_temperature_min_unit": "Celsius",
    "process_temperature_max": "",
    "process_temperature_max_unit": "Celsius",
    "ambient_temperature_min": "",
    "ambient_temperature_min_unit": "Celsius",
    "ambient_temperature_max": "",
    "ambient_temperature_max_unit": "Celsius"
  }
  params.language_code = action.payload['lang'] ? action.payload['lang'] : 'en-us';
  params.business_unit = action.payload['bu_code'] ? action.payload['bu_code'] : '';
  params.industry = action.payload['LTA_r_Industry'] ? action.payload['LTA_r_Industry'] : '';
  params.media_state = action.payload['hLTA_r_MediaState'] ? action.payload['hLTA_r_MediaState'] : '';
  params.application = action.payload['hLTA_r_Application'] ? action.payload['hLTA_r_Application'] : '';
  params.measured_variable = action.payload['hLTA_r_MeasuredVariable'] ? action.payload['hLTA_r_MeasuredVariable'] : '';
  params.communication_protocol = action.payload['hLTA_r1_sCommunication'] ? action.payload['hLTA_r1_sCommunication'] : '';
  params.application_requirements = action.payload['hLTA_r_chkApplicationRequirements'] ? action.payload['hLTA_r_chkApplicationRequirements'].join(';') : '';
  params.fitting_location = action.payload['hLTA_r2_ctbFittingLocation'] ? action.payload['hLTA_r2_ctbFittingLocation'] : '';
  params.vessel_type = action.payload['hLTA_r2_sVesselType'] ? action.payload['hLTA_r2_sVesselType'] : '';
  params.vessel_dimension = action.payload['hLTA_r2_tbDimension']?.vessel_dimension ? action.payload['hLTA_r2_tbDimension']?.vessel_dimension : '';
  if (params.vessel_dimension && action.payload['hLTA_r2_tbDimension']?.vessel_dimension_unit) {
    params.vessel_dimension_unit = action.payload['hLTA_r2_tbDimension']?.vessel_dimension_unit
  };
  if (!params.vessel_dimension) {
    params.vessel_dimension_unit = ''
  }
  params.process_pressure_min = action.payload['hLTA_r2_tblOperatingConditions']?.process_pressure_min ? action.payload['hLTA_r2_tblOperatingConditions']?.process_pressure_min : '';
  if (params.process_pressure_min && action.payload['hLTA_r2_tblOperatingConditions']?.process_pressure_unit) {
    params.process_pressure_min_unit = action.payload['hLTA_r2_tblOperatingConditions']?.process_pressure_unit;
  }
  if (!params.process_pressure_min) {
    params.process_pressure_min_unit = ''
  }
  params.process_pressure_max = action.payload['hLTA_r2_tblOperatingConditions']?.process_pressure_max ? action.payload['hLTA_r2_tblOperatingConditions']?.process_pressure_max : '';
  if (params.process_pressure_max && action.payload['hLTA_r2_tblOperatingConditions']?.process_pressure_unit) {
    params.process_pressure_max_unit = action.payload['hLTA_r2_tblOperatingConditions']?.process_pressure_unit;
  }
  if (!params.process_pressure_max) {
    params.process_pressure_max_unit = ''
  }
  params.process_temperature_min = action.payload['hLTA_r2_tblOperatingConditions']?.process_temperature_min ? action.payload['hLTA_r2_tblOperatingConditions']?.process_temperature_min : '';
  if (params.process_temperature_min && action.payload['hLTA_r2_tblOperatingConditions']?.process_temperature_unit) {
    params.process_temperature_min_unit = action.payload['hLTA_r2_tblOperatingConditions']?.process_temperature_unit;
  }
  if (!params.process_temperature_min) {
    params.process_temperature_min_unit = ''
  }
  params.process_temperature_max = action.payload['hLTA_r2_tblOperatingConditions']?.process_temperature_max ? action.payload['hLTA_r2_tblOperatingConditions']?.process_temperature_max : '';
  if (params.process_temperature_max && action.payload['hLTA_r2_tblOperatingConditions']?.process_temperature_unit) {
    params.process_temperature_max_unit = action.payload['hLTA_r2_tblOperatingConditions']?.process_temperature_unit;
  }
  if (!params.process_temperature_max) {
    params.process_temperature_max_unit = ''
  }

  params.ambient_temperature_min = action.payload['hLTA_r2_tblOperatingConditions']?.ambient_temperature_min ? action.payload['hLTA_r2_tblOperatingConditions']?.ambient_temperature_min : '';
  if (params.ambient_temperature_min && action.payload['hLTA_r2_tblOperatingConditions']?.ambient_temperature_unit) {
    params.ambient_temperature_min_unit = action.payload['hLTA_r2_tblOperatingConditions']?.ambient_temperature_unit
  }
  if (!params.ambient_temperature_min) {
    params.ambient_temperature_min_unit = ''
  }
  params.ambient_temperature_max = action.payload['hLTA_r2_tblOperatingConditions']?.ambient_temperature_max ? action.payload['hLTA_r2_tblOperatingConditions']?.ambient_temperature_max : '';
  if (params.ambient_temperature_max && action.payload['hLTA_r2_tblOperatingConditions']?.ambient_temperature_unit) {
    params.ambient_temperature_max_unit = action.payload['hLTA_r2_tblOperatingConditions']?.ambient_temperature_unit;
  }
  if (!params.ambient_temperature_max) {
    params.ambient_temperature_max_unit = ''
  }
  const params2 = {
    business_unit_code: action.payload.bu_code,
    p_session_navigation_id: action.payload.sn_id,
    p_ne_id: action.payload.ne_id,
    p_request_json: JSON.stringify({}),
    lang: action.payload.lang,
  }
  try {
    if (notrenderd) {
      const response1: ResponseGenerator = yield call(getProductionListAPI, params);
      const response: ResponseGenerator = yield call(getRightAnchorDataAPI, params2);
      response.data[0].apiData = response1.data;
      yield put({ type: Constant.STORE_RIGHT_ANCHOR_SUCCESS, data: response.data });
    } else {
      const response1: ResponseGenerator = yield call(getProductionListAPI, params);
      yield put({
        type: Constant.STORE_UPDATE_RIGHT_KEY_ANCHOR,
        payload: {
          name: 'hLTA_r3_production',
          options: response1.data,
          ne_id: action.payload.ne_id,
        }
      });
      yield put({ type: Constant.STORE_PRODUCTION_LIST_SUCCESS, data: response1.data.data });
    }
  } catch (error:any) {
    yield put({ type: Constant.STORE_PRODUCTION_LIST_ERROR, error });
    yield put(updateAlertMessage(getErrorMessage(error)));
  }
}

export function* storeActiveSession() {
  yield takeLatest(Constant.STORE_ACTIVE_SESSION, storeActiveSessionSaga);
}

export function* storeActiveSessionSaga(action: any) {
  try {
    const { data } = yield call(getExtOktaSession, action.payload);
    yield put({ type: Constant.STORE_ACTIVE_SESSION_SUCCESS, data });
  } catch (error) {
    try {
      const { data } = yield call(getIntOktaSession, action.payload);
      yield put({ type: Constant.STORE_ACTIVE_SESSION_SUCCESS, data });
    } catch (error) {
      yield put({ type: Constant.STORE_ACTIVE_SESSION_ERROR, error });
    }
  }
}

export function* deleteActiveSession() {
  yield takeLatest(Constant.DELETE_ACTIVE_SESSION, deleteActiveSessionSaga);
}
export function* deleteActiveSessionSaga(action: any) {
  try {
    const response1: ResponseGenerator = yield call(deleteUserOktaSession, action.payload);
    const response2: ResponseGenerator = yield call(deleteIntUserOktaSession, action.payload);
    yield put({ type: Constant.DELETE_ACTIVE_SESSION_SUCCESS, data: null});
  } catch (error) {
    try {
      const response2: ResponseGenerator = yield call(deleteIntUserOktaSession, action.payload);
      yield put({ type: Constant.DELETE_ACTIVE_SESSION_SUCCESS, data: null });
    } catch (error) {
      yield put({ type: Constant.DELETE_ACTIVE_SESSION_SUCCESS, data: null });
    }
  }
}

export function* getUOMData() {
  yield takeLatest(Constant.STORE_UOM_DATA, getUOMDataSaga);
}
export function* getUOMDataSaga(action: any) {
  try {
    yield put({ type: Constant.UPDATE_LOADING, payload: true });
    const { data } = yield call(fetchUOMData, action.payload);
    // for reset
    let defaultEnglish =  data.defaultUnits.English;
    let defaultMetric =  data.defaultUnits.Metric;
    // for reset
    let allValue = data.defaultUnits.English;
    let englishValue = data.defaultUnits.English;
    let metricValue = data.defaultUnits.Metric;
    
    let allOptions = Object.keys(allValue).reduce((res: any, key: any) => {
      res[key] = data[key].map((item: any)=>{
        item.label = item.unitName;
        item.value = item.unitKey
        return item
      });
      return res;
    }, {});

    let englishOptions = Object.keys(allValue).reduce((res: any, key: any) => {
      res[key] = allOptions[key].filter((item:any)=> item.systemUnit == "English");
      return res;
    }, {});

    let metricOptions = Object.keys(allValue).reduce((res: any, key: any) => {
      res[key] = allOptions[key].filter((item:any)=> item.systemUnit == "Metric");
      return res;
    }, {});

    yield put({ type: Constant.UPDATE_LOADING, payload: false });
    const userPreferencesValue:SelectGenerator = yield select(state => {
      return state.userPreferencesValue
    });
    if(userPreferencesValue && userPreferencesValue.displayUnit){
      yield put({ type: Constant.GET_UOM_DATA_SUCCESS, data:{
       allOptions, englishOptions, metricOptions, defaultEnglish, defaultMetric
      } });
    } else {
      yield put({ type: Constant.GET_UOM_DATA_SUCCESS, data:{
        allValue, englishValue, metricValue, allOptions, englishOptions, metricOptions, defaultEnglish, defaultMetric
      } });
    }
   
  } catch (error) {
    yield put({ type: Constant.GET_UOM_DATA_ERROR, error });
  }
}


export function* getFetchSizing() {
  yield takeLatest(Constant.GET_FETCH_SIZING, getFetchSizingSaga);
}
export function* getFetchSizingSaga(action: any) {
  try {
    const response: ResponseGenerator = yield call(onFetchSizingAPI);

    yield put({ type: Constant.UPDATE_RIGHT_DATA, payload: []});
  } catch (error) {
    console.log("error", error)
  }
}

export function* updateUserPreference() {
  yield takeLatest(Constant.UPDATE_USER_PREFERENCE_DATA, updateUserPreferenceSaga);
}
export function* updateUserPreferenceSaga(action: any) {
  try {
    const { data } = yield call(updateUserPreferenceAPI, action.payload);    
    yield put({ type: Constant.PUT_UOM_DATA_SUCCESS, data });
    yield put({ type: Constant.UPDATE_PREFERENCE_DATA, payload: data.preferences});
    yield put({ type: Constant.UPDATE_OPTIONS_BY_PREFERECES});
  } catch (error) {
    yield put({ type: Constant.PUT_UOM_DATA_ERROR, error });
  }
}

export function* getUserPreference() {
  yield takeLatest(Constant.GET_USER_PREFERENCE, getUserPreferenceSaga);
}
export function* getUserPreferenceSaga(action: any) {
  try {
    
    const { data } = yield call(getUserPreferenceAPI, action.payload); 
    yield put({ type: Constant.UPDATE_PREFERENCE_DATA, payload: data.preferences});
    yield put({ type: Constant.UPDATE_USER_ID, payload: data.userId});
    // get all MySizing
    yield put(getAllSizing(data.userId));
    
  } catch (error) {
    yield put({ type: Constant.PUT_UOM_DATA_ERROR, error });
  }
}

